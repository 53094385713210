<template>
  <v-layout wrap>
    <v-flex
      xs12
    >
      <material-app-card
        class="wbs-list"
        :color="section_color"
        icon="mdi-text-box"
        title="Cost Center"
      >
        <div class="mb-3 px-4 pt-3 pb-5 grey lighten-2">
          <div class="font-weight-bold" style="text-align:left">Filter</div>
          <v-text-field
            outlined
            dense
            hide-details
            background-color="white"
            color="grey"
            class="mb-3"
            prepend-inner-icon="mdi-magnify"
            v-model="table.costCenter.filter.keyword"
          ></v-text-field>
          <div class="d-flex align-top justify-space-between">
            <div>
              <div style="width:135px!important">
                <v-checkbox
                  dense
                  hide-details
                  :color="section_color"
                  class="mt-n1 ml-1"
                  background-color="grey lighten-2"
                  label="Cost Center"
                  v-model="table.costCenter.filter.basedOn.costCenter"
                ></v-checkbox>
              </div>
              <div style="width:160px!important">
                <v-checkbox
                  dense
                  hide-details
                  :color="section_color"
                  class="mt-n1 ml-1"
                  background-color="grey lighten-2"
                  label="FP&A Approver"
                  v-model="table.costCenter.filter.basedOn.fpaApproverEmail"
                ></v-checkbox>
              </div>
              <div v-if="false" style="width:80px!important">
                <v-checkbox
                  dense
                  hide-details
                  :color="section_color"
                  class="mt-n1 ml-1"
                  background-color="grey lighten-2"
                  label="Tag"
                  v-model="table.costCenter.filter.basedOn.tags"
                ></v-checkbox>
              </div>
            </div>
            <div>
              <v-btn
                dark
                class="ma-0 mt-1"
                @click="showMultipleUpdate"
              >Multiple Update</v-btn>
            </div>
          </div>
        </div>
        <v-data-table
          hide-default-footer
          :loading="table.costCenter.loading.data"
          :headers="table.costCenter.headers"
          :items="tableCostCenterItemsDisplay"
          :items-per-page="table.costCenter.itemsPerPage"
          :page="table.costCenter.page"
        >
          <template v-slot:item="props">
            <tr>
              <td style="text-align:left;color:grey;">
                {{ props.item.costCenter }}
              </td>
              <td style="text-align:center;color:right;padding-left:0;padding-right:0">
                <v-tooltip left :disabled="isMobile">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-show="!props.item.fpaApproverEmail"
                      class="orange--text text--lighten-2"
                      small
                      v-on="on"
                    >mdi-alert-circle-outline</v-icon>
                  </template>
                  <div class="py-1">From SAP</div>
                </v-tooltip>
              </td>
              <td style="text-align:left;color:grey;">
                <v-text-field
                  dense
                  outlined
                  hide-details
                  background-color="white"
                  :color="section_color"
                  :disabled="!props.item.updating"
                  :placeholder="props.item.fpaApproverEmailSap"
                  v-model="props.item.fpaApproverEmail"
                >
                </v-text-field>
              </td>
              <!-- <td style="text-align:left;color:grey;">
                <v-chip
                  v-for="(tx, ti) in props.item.tags"
                  :key="'table-cc-item-'+props.index+'-tag-'+ti"
                  small
                >{{ tx }}</v-chip>
              </td> -->
              <td style="text-align:right;color:grey;">
                <v-btn
                  v-if="props.item.updating && !props.item.saving && ((props.item.fpaApproverEmail || '').trim() !== (props.item.fpaApproverEmailOrigin || '').trim())"
                  fab
                  x-small
                  dark
                  :color="section_color"
                  class="ml-1"
                  @click="saveUpdateCostCenter(props.item)"
                ><v-icon>mdi-content-save</v-icon></v-btn>
                <v-btn
                  v-else
                  fab
                  x-small
                  dark
                  disabled
                  elevation="0"
                  class="ml-1"
                ></v-btn>
                <v-btn
                  v-if="!props.item.updating"
                  fab
                  x-small
                  dark
                  :color="section_color"
                  class="ml-1"
                  @click="updateCostCenter(props.item)"
                ><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn
                  v-else-if="!props.item.saving"
                  fab
                  x-small
                  color="grey lighten-4"
                  class="ml-1"
                  @click="cancelUpdateCostCenter(props.item)"
                ><v-icon>mdi-cancel</v-icon></v-btn>
                <v-btn
                  v-else
                  fab
                  x-small
                  elevation="0"
                  color="white"
                  class="ml-1"
                  loading
                ></v-btn>
              </td>
            </tr>
          </template>
          <template v-slot:footer="props">
            <div class="d-flex align-center justify-end pt-2" style="border-top: 1px solid #ddd">
              <span class="text-caption mr-7">{{ (props.props.pagination.page-1)*props.props.pagination.itemsPerPage+1 }}-{{ Math.max(Math.min(props.props.pagination.page*props.props.pagination.itemsPerPage,props.props.pagination.itemsLength),1) }} of {{ props.props.pagination.itemsLength }}</span>
              <v-btn icon class="mr-3"
                :disabled="props.props.pagination.page===1"
                @click="table.costCenter.page=Math.max(props.props.pagination.page-1,1)"
              ><v-icon>mdi-chevron-left</v-icon></v-btn>
              <v-btn icon class="mr-3"
                :disabled="props.props.pagination.page*props.props.pagination.itemsPerPage>props.props.pagination.itemsLength"
                @click="table.costCenter.page=Math.min(props.props.pagination.page+1,Math.ceil(tableCostCenterItemsDisplay.length / table.costCenter.itemsPerPage))"
              ><v-icon>mdi-chevron-right</v-icon></v-btn>
            </div>
          </template>
        </v-data-table>
      </material-app-card>
    </v-flex>
    <v-dialog
      v-model="dialog.multipleUpdate.display"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title
          class="mb-4"
        >
          <div>Multiple Update</div>
          <v-spacer/>
          <v-btn icon
            :disabled="dialog.multipleUpdate.saving"
            @click="cancelMultipleUpdate"
          ><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <div>Cost Centers (,)</div>
          <v-textarea
            v-model="dialog.multipleUpdate.data.costCenters"
            outlined
            dense
            hide-details
            class="mb-4"
            placeholder="etc. 00010001,00010002, ..."
          ></v-textarea>
          <div>FP&amp;A Approver</div>
          <v-text-field
            v-model="dialog.multipleUpdate.data.fpaApproverEmail"
            outlined
            dense
            hide-details
            placeholder="(FP&A Owner from SAP if blank)"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            width="75px"
            v-show="!dialog.multipleUpdate.saving"
            @click="cancelMultipleUpdate"
          >Cancel</v-btn>
          <v-btn
            dark
            width="75px"
            :loading="dialog.multipleUpdate.saving"
            @click="saveMultipleUpdate"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { section_color } from "@/config";
import { xPost } from '@/functions/http/axios'

export default {
  data () { return {
    me: {
      display: false,
    },
    table: {
      costCenter: {
        page: 1,
        itemsPerPage: 10,
        filter: {
          keyword: null,
          basedOn: {
            costCenter: true,
            fpaApproverEmail: true,
            tags: true,
          },
        },
        loading: {
          data: true,
        },
        headers: [
          {
            value: 'costCenter',
            text: 'Cost Center',
            sortable: true,
            align: 'left',
            // width: '270px',
          },
          {
            text: '',
            sortable: false,
            align: 'right',
            width: '8px',
            class: 'px-0',
          },
          {
            value: 'fpaApproverEmail',
            text: 'FP&A Approver',
            sortable: true,
            align: 'left',
            // width: '400px',
          },
          // {
          //   text: 'Tag',
          //   sortable: false,
          //   align: 'left',
          //   // width: '45px',
          // },
          {
            text: '',
            sortable: false,
            align: 'right',
            // width: '45px',
          },
        ],
        items: [],
      },
    },
    dialog: {
      multipleUpdate: {
        display: false,
        saving: false,
        data: {
          costCenters: '',
          fpaApproverEmail: '',
        },
      },
    },
  }},
  computed: {
    ...mapGetters('user', [
      'loginStatus',
      // 'userEmail',
      // 'userInfo',
    ]),
    ...mapGetters('app', [
      'isMobile'
    ]),
    section_color() {
      return section_color['admin']
    },

    // data
    tableCostCenterItemsDisplay () {
      return this.table.costCenter.items.filter(el => 
        !(this.table.costCenter.filter.keyword)
        || (
          !this.table.costCenter.filter.basedOn.costCenter
          && !this.table.costCenter.filter.basedOn.fpaApproverEmail
          && !this.table.costCenter.filter.basedOn.tags
        )
        || (
          this.table.costCenter.filter.basedOn.costCenter
          && (typeof el?.costCenter === 'string' && el.costCenter.toLowerCase().search(this.table.costCenter.filter.keyword.toLowerCase())>-1)
        )
        || (
          this.table.costCenter.filter.basedOn.fpaApproverEmail
          && (typeof el?.fpaApproverEmail === 'string' && el.fpaApproverEmail.toLowerCase().search(this.table.costCenter.filter.keyword.toLowerCase())>-1)
        )
        || (
          this.table.costCenter.filter.basedOn.tags
          && el?.tags instanceof Array
          && el.tags.some(tag => 
            typeof tag === 'string' && tag.toLowerCase().search(this.table.costCenter.filter.keyword.toLowerCase())>-1
          )
        )
      )
    },
    countTableCostCenterItemsDisplay () {
      return this.tableCostCenterItemsDisplay.length
    }
  },
  methods: {
    ...mapActions('app', ['setMainTitle']),

    // functions
    /**
     * check user access
     */
    async checkUserAccess () {
      let requestAdminAccess = this.postRequestAdminAccess()
      if (!(await requestAdminAccess)) {
        this.$router.push('/')
      }
    },
    /**
     * init page content, functions
     */
    init () {
      this.me.display = true
      this.refreshCostCenterData()
    },
    async refreshCostCenterData () {
      this.table.costCenter.loading.data = true
      this.table.costCenter.items = (await this.postRequestCostCenterData()).map(el => ({
        costCenter: el?.Cost_Center,
        tags: JSON.parse(el?.Tags_Json),
        fpaApproverEmail: el?.FPA_Approver_Email,
        fpaApproverEmailSap: el?.FPA_Approver_Email_Sap,
        updating: false,
        saving: false,
        fpaApproverEmailOrigin: el?.FPA_Approver_Email,
      }))
      this.table.costCenter.page = Math.min(this.table.costCenter.page, Math.ceil(this.tableCostCenterItemsDisplay.length / this.table.costCenter.itemsPerPage))
      this.table.costCenter.loading.data = false
    },
    /**
     * update cc item
     */
    updateCostCenter (item) {
      item.fpaApproverEmailOrigin = item.fpaApproverEmail
      item.updating = true
    },
    cancelUpdateCostCenter (item) {
      item.fpaApproverEmail = item.fpaApproverEmailOrigin
      item.updating = false
    },
    async saveUpdateCostCenter (item) {
      if (!item.updating) return
      item.saving = true
      let count
      if (item?.costCenter) {
        count = await this.postSaveCostCenterSpec([item])
      }
      if (count) {
        item.fpaApproverEmail = (item.fpaApproverEmail ?? '').trim()
        item.fpaApproverEmailOrigin = item.fpaApproverEmail
        item.updating = false
      } else if (count == 0) {
        this.$eventBus.$emit('snackbar', { text: 'No change', type: 'warning' })
      } else {
        this.$eventBus.$emit('snackbar', { text: 'Fail to save', type: 'error' })
      }
      item.saving = false
    },
    /**
     * multiple update
     */
    showMultipleUpdate () {
      this.clearDialogMultipleUpdateData()
      this.dialog.multipleUpdate.display = true
    },
    cancelMultipleUpdate () {
      this.clearDialogMultipleUpdateData()
      this.dialog.multipleUpdate.display = false
    },
    async saveMultipleUpdate () {
      let items = (this.dialog.multipleUpdate.data.costCenters ?? '')
        .split(',')
        .filter(el => (el ?? '').trim())
        .map(el => ({
          costCenter: el.trim(),
          fpaApproverEmail: (this.dialog.multipleUpdate.data.fpaApproverEmail ?? '').trim()
        }))
      if (!items.length) {
        this.$eventBus.$emit('snackbar', { text: 'Please input valid Cost Centers', type: 'error' })
        return
      }
      this.dialog.multipleUpdate.saving = true
      let count = await this.postSaveCostCenterSpec(items)
      if (count) {
        this.$eventBus.$emit('snackbar', { text: 'Update Saved', type: 'success' })
        this.refreshCostCenterData()
        this.dialog.multipleUpdate.display = false
      } else if (count == 0) {
        this.$eventBus.$emit('snackbar', { text: 'No change', type: 'warning' })
      } else {
        this.$eventBus.$emit('snackbar', { text: 'Fail to save', type: 'error' })
      }
      this.dialog.multipleUpdate.saving = false
    },
    clearDialogMultipleUpdateData () {
      this.dialog.multipleUpdate.data.costCenters = ''
      this.dialog.multipleUpdate.data.fpaApproverEmail = ''
    },
    
    // post
    /**
     * request access
     */
    async postRequestAdminAccess() {
      try {
        const res = await xPost(
            'admin',
            'access',
            {
              loginStatus: this.loginStatus
            }
          )
          if (res.data.status === 200) {
            return res.data.vendor_cc_spec ?? false
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                // this.$router.push('/login')
              }
            } catch (e) {}
            throw ''
          }
      } catch (err) {
        console.log('Error [request admin access]')
        // console.log(err)
      }
      return false
    },
    async postRequestCostCenterData() {
      try {
        const res = await xPost(
            'vendor_contract',
            'cc_data_spec',
            {
              loginStatus: this.loginStatus
            }
          )
          if (res.data.status === 200) {
            let ret = res.data.cc_data_spec ?? []
            if (!(ret instanceof Array)) { ret = [] }
            return ret
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                // this.$router.push('/login')
              }
            } catch (e) {}
            throw ''
          }
      } catch (err) {
        console.log('Error [request Cost Center Data]')
        // console.log(err)
      }
      return false
    },
    async postSaveCostCenterSpec(data) {
      try {
        const res = await xPost(
            'vendor_contract',
            'sav_cc_data_spec',
            {
              loginStatus: this.loginStatus,
              data
            }
          )
          if (res.data.status === 200) {
            return res.data.count ?? 0
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                // this.$router.push('/login')
              }
            } catch (e) {}
            throw ''
          }
      } catch (err) {
        console.log('Error [request Cost Center Data]')
        // console.log(err)
      }
      return false
    },
  },
  created () {
    // set title
    this.setMainTitle('Admin -- Vendor Contract - Cost Center')
    // check access
    this.checkUserAccess()
    this.init()
  },
  watch: {
    countTableCostCenterItemsDisplay: {
      handler (nv) {
        this.table.costCenter.page = Math.min(this.table.costCenter.page, Math.ceil(nv / this.table.costCenter.itemsPerPage))
      },
    }
  },
}
</script>

<style>

</style>